export default function workspaceService(api){
  return {
    getWorkspaceExecutions: async function(handle, payload){
      return api.get(`/${handle}/workspace/executions`, {
        baseURL: process.env.VUE_APP_SERVER_INTERNALURL,
        params:{
          ...payload
        }
      })
    },
    getWorkspaceOverview(handle){
      return api.get(`/${handle}/workspace/overview`,{
        baseURL: process.env.VUE_APP_SERVER_INTERNALURL,
      })

    }
  }
}